import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthHttpService } from './core/services/auth-http.service';
import { map } from 'rxjs/operators';

export const loginGuard: CanActivateFn = (route, state) => {
  const authHttpService = inject(AuthHttpService);
  const router = inject(Router);

  return authHttpService.isUserAuthenticated().pipe(
    map((response) => {
      if (!response) {
        router.navigate(['login']);
      }
      return response;
    }),
  );
};
