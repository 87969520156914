import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil, tap } from 'rxjs';

interface NavigationPage {
  pageName: string;
  url: string;
  active: boolean;
}

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private currentUrl: string = '';

  navigationBarPages: NavigationPage[] = [
    { pageName: "Page d'accueil", url: '/accueil', active: false },
    { pageName: 'Blog des beautés', url: '/blog', active: false },
    { pageName: 'Compteurs de pensées', url: '/compteurs', active: false },
    { pageName: 'Évenements', url: '/events', active: false },
    { pageName: 'Carte', url: '/map', active: false },
  ];

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        tap((event) => {
          if (event instanceof NavigationEnd) {
            this.currentUrl = event.url.split('?')[0];
            if (!this.currentUrl || this.currentUrl === '/') {
              this.currentUrl = '/accueil';
            }
            this.updateActivePage();
          }
        }),
      )
      .subscribe();
  }

  private updateActivePage(): void {
    for (let page of this.navigationBarPages) {
      page.active = page.url === this.currentUrl;
    }
  }

  navigateTo(url: string): void {
    const validUrl = this.navigationBarPages.find((page) => page.url === url);

    if (validUrl) {
      this.router.navigateByUrl(url).catch((err) => {
        console.error('Navigation failed:', err);
      });
    } else {
      console.warn(`Attempted to navigate to an invalid URL: ${url}`);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
